body {
  width: 100%;
  height: 100%;
  background-color: #E6E7EB;
}

.container {
  display: grid;
  min-height: 100vh;
  grid-template-rows: 113px 1fr;
  justify-items: center;
}

.header {
  background-color: #fff;
  height: 113px;
  width: 100%;
}

.content {
  display: grid;
  margin-top: -62.5px;
  margin-bottom: 30px;
  align-content: flex-start;
  grid-gap: 20px;
  width: 100%;
  max-width: 1200px;
}

.profile {
  margin: 0 20px;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 10px;
  justify-items: center;
}

.profile-picture {
  > img {
    border-radius: 65px;
  }
}

.profile-desc {
  text-align: center;
}

.profile-desc__title {
  font-size: 20px;
  font-weight: 600;
}

.profile-desc__sub {
  font-size: 16px;
  font-weight: 300;
}

.links {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 15px;
  width: 100%;
  max-width: 680px;
  justify-self: center;
}

.link {
  margin: 0 20px;
  position: relative;
  display: grid;
  align-content: center;
  justify-content: center;
  height: 60px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 0px 15px rgb(17 21 26 / 15%);
  font-weight: 300;
  
  > img {
    position: absolute;
    top: 15px;
    left: 15px;
  }
}

@media only screen and (min-width: 1240px) {
  .container {
    margin: 0 auto;
  }
}